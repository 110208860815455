@import "../../assets/styles/variables";
.login-container {
  width: 100%;
  height: 100vh;
  background-image: url(../../assets/img/login.png);
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
  .form-container {
    border-radius: 10px;
    background: #fff;
    padding: 20px;
    min-width: 400px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    input {
      height: 52px;
      padding-left: 43px;
    }
  }
  .login-form {
    max-width: 400px;
  }
}

.label-icon {
  color: lighten($text-color, 40%);
  position: absolute;
  left: 10px;
  top: 15px;
}

.login-icon {
  background-color: $primary;
  padding: 15px;
  border-radius: 50%;
  color: $white;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}