@import '../../assets/styles/variables.scss';

.custom-table {
  // background-color: #c0e5fa9f;
  // background-image: linear-gradient(to top, #a0d2fc, #b3ddff, #b6cbee, #e0e4f6, #ffffff);
  border-radius: .3rem;
  border: 1px solid rgba(0, 0, 0, 0.14);
  .rdt_TableHeader {
    display: none;
  }
  .rdt_TableHeadRow {
  //background-image: linear-gradient(to right, $primary, $primary_hover);
     background-color: $primary;
    color: white;
  }
  .rdt_TableBody {
    .rdt_TableRow {
      color: rgba(0,0,0,.87) !important;
    }
  }
}