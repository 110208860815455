@import '../../../assets/styles/variables.scss';

.create-button {
  //background: $primary !important;
  padding-bottom: 5px;
  padding-top: 8px;
  border: 0;
  // padding: 0 6px;
  // margin: 6px 8px;
  font-size: 14px;
  font-weight: 500;
  align-items: center !important;
  .name {
    vertical-align: super;
  }
  div {
    display: inline;
  }
  i {
    font-size: 25px;
    padding-right: 8px;
  }
  &:hover {
    color: lighten($primary_hover, 10%);
  }
  &:focus {
    outline: none !important;
  }
}

@media (max-width: 576px) {
  .create-button {
    .name {
      display: none;
    }
  }
}
