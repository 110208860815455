.modal-backdrop.show {
  opacity: 0.5 !important;
}

.modal-content {
  // border: none !important;
  // color: white;
  // background: linear-gradient(40deg,#45cafc,#303f9f)!important;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,.16),0 2px 10px 0 rgba(0,0,0,.12)!important;
  .modal-header {
    border-bottom: 0px;
    // .close {
    //   color: white;
    // }
  }
  .modal-footer {
    border-top: 0px;
  }
}