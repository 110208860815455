@import '../../assets/styles/variables';
.left-menu a{
    border: 0.1rem solid;
    background-color: $primary;
    color: white;
   
}
.left-menu .nav-item .nav-link.active{
    background:$primary_hover !important;
    color: white !important;
}