@import "../../assets/styles/variables";
.schedules-page-title {
  align-items: center;
  border-bottom: 2px solid rgba(0, 0, 0, 0.14);
  h1 {
    // line-height: 54px;
    font-size: 24px !important;
  }
}

.data-table {
  padding-top: 30px;
}

.expiry-text {
  span {
    background-color: transparent;
    border: none;
    font-size: 14px;
  }
}

.tooltip-toggle {
  cursor: pointer;
  width: 100%;
  height: 100%;
  //border-right: 1px solid #fff;
  //Tooltip text container
  &::before {
    position: absolute;
    background-color: #2b222a;
    border-radius: 5px;
    color: #fff;
    padding: 10px !important;
    content: attr(
      tooltip
    ); //This pulls in the text from the element with the tooltip
    padding: 0rem 0.3rem;
    text-transform: none;
    transition: all 0.5s ease;
    top: 0;
    left: 30%;
    z-index: 3;
  }

  //Setting up the transition
  &::before,
  &::after {
    color: #efefef;
    font-size: 14px;
    opacity: 0;
    pointer-events: none;
    text-align: center;
  }

  //Triggering the transition
  &:hover::before,
  &:hover::after {
    opacity: 1;
    transition: all 0.75s ease;
  }
}

.cancelled-bar {
  background-color: $orange;
}

.inprogress-bar {
  background-color: $yellow;
}

.newSchedBtn {
  .dropdown-menu {
    top: -5px !important;
  }
}

.filterBtn {
  .dropdown-item {
    // height: 45px;
    padding: 10px 25px;
    i {
      // float: right;
    }
  }
}

.removeRowButton {
  margin-top: 31px;
}

.create-schedule-card {
  .list-group-item {
    padding: 0.3rem 0.75rem;
    b {
      font-weight: 500;
    }
  }
}

.bulk-vin-preview-table {
  max-height: 320px;
  overflow: auto;
  th {
    font-weight: 500;
  }
}

.search-box {
  button {
    width: 100%;
  }
}